import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from './Widget';
import NoCimateActions from './NoClimateActions';

function DetailsMobile({ scope, unitText, scopeColor, getColorByScore, getClimateAlignementInfos, getHexColorWithOpacity, dataUsable, dataNotComparable, flag }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mt-6 flex justify-between">
        <div className="flex w-1/2 items-center text-xs text-greyFour">
          {t('CLIMATE_ACTIONS.ACTUAL_REDUCTION')}
        </div>
        {scope?.degreeScore >= 2 && !dataUsable && !dataNotComparable && (flag ? scope?.degreeScore >= 3 : true) ? (
          <div className="text-center w-1/2">
            <div className="text-lg text-greyOne font-moskauGrotesk font-semibold">
              {scope?.percentDelta > 0 ? '+' : ''}{scope?.percentDelta}%
            </div>
            <div className="text-xs text-greyTwo">
              {t(unitText[scope?.reduceType])}
            </div>
            <div className="text-xs text-greyTwo">
              {t('CLIMATE_ACTIONS.BETWEEN')} {scope?.startYear} {t('CLIMATE_ACTIONS.AND')} {scope?.endYear}
            </div>
          </div>
        ) : ('')}
      </div>
      <div className="mt-6 flex justify-between">
        <div className="flex w-1/2 items-center text-xs text-greyFour">
          {t('CLIMATE_ACTIONS.REDUCTION_SPEED')}
        </div>
        {scope?.degreeScore >= 2 && !dataUsable && !dataNotComparable && (flag ? scope?.degreeScore >= 3 : true) ? (
          <div className="w-1/2 text-center text-greyOne text-lg flex flex-col justify-end">
            <div className="font-moskauGrotesk font-semibold">
              {(scope && scope.percentDelta && scope?.startYear && scope.endYear) && (scope.percentDelta / (scope.endYear - scope.startYear)).toFixed(2) > 0 ? '+' : ''}{(scope && scope.percentDelta && scope?.startYear && scope.endYear) && (scope.percentDelta / (scope.endYear - scope.startYear)).toFixed(2)}% / {t('CLIMATE_ACTIONS.YEAR')}
            </div>
            <div className="text-xs text-greyTwo">
              {t(unitText[scope?.reduceType])}
            </div>
          </div>
        ) : ('')}
      </div>
      <div className="mt-6 flex justify-between">
        <div className="flex w-1/2 items-center text-xs text-greyFour">
          {t('CLIMATE_ACTIONS.CLIMATE_ALIGNEMENT')}
        </div>
        <div className="w-1/2">
          {(dataUsable || dataNotComparable) ? (
            <NoCimateActions dataUsable={dataUsable} dataNotComparable={dataNotComparable} />
          ) : (
            <Widget
              getColorByScore={getColorByScore}
              getClimateAlignementInfos={getClimateAlignementInfos}
              getHexColorWithOpacity={getHexColorWithOpacity}
              degreeScore={scope?.degreeScore}
              color={scopeColor}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DetailsMobile;
